#primaryDrawer {
    position: absolute;
    top: 72px;
    bottom: 0;
    transition:
        left 400ms ease-out,
        width 400ms ease-out;
    background-color: #fff;
    z-index: 11;
    overflow-y: auto;
    overflow-x: hidden;
}
#secondaryDrawer {
    position: absolute;
    top: 72px;
    bottom: 0;
    transition:
        left 400ms ease-out,
        width 400ms ease-out;
    background-color: #eee;
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;
}

.disabledOverlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
}

#primaryDrawer.open,
#secondaryDrawer.open {
    left: 0;
}

@media (max-width: 960px) {
    #primaryDrawer {
        width: 100vw;
        left: -100vw;
    }
    #secondaryDrawer {
        width: 100vw;
        left: -100vw;
    }
}
@media (min-width: 961px) {
    .small {
        width: 300px;
        left: -300px;
    }
    .medium {
        width: 400px;
        left: -400px;
    }
    .large {
        width: 500px;
        left: -500px;
    }

    #secondaryDrawer.p_small.open {
        left: 300px;
    }
    #secondaryDrawer.p_medium.open {
        left: 400px;
    }
    #secondaryDrawer.p_large.open {
        left: 500px;
    }

    #primaryDrawer {
        z-index: 11;
    }
    #secondaryDrawer {
        z-index: 10;
    }
}

#collapse {
    position: absolute;
    left: 8px;
    bottom: 6px;
    width: 32px;
    height: 32px;
    z-index: 99;
}
.previewContainer {
    transition: padding 250ms;
}

.primary_small {
    padding-left: 300px;
}
.primary_small.secondary_small {
    padding-left: 600px;
}
.primary_small.secondary_medium {
    padding-left: 700px;
}
.primary_small.secondary_large {
    padding-left: 800px;
}
.primary_medium {
    padding-left: 400px;
}
.primary_medium.secondary_small {
    padding-left: 700px;
}
.primary_medium.secondary_medium {
    padding-left: 800px;
}
.primary_medium.secondary_large {
    padding-left: 900px;
}
.primary_large {
    padding-left: 500px;
}
.primary_large.secondary_small {
    padding-left: 800px;
}
.primary_large.secondary_medium {
    padding-left: 900px;
}
.primary_large.secondary_large {
    padding-left: 1000px;
}
