.assetGrid {
    padding: 2px;
}

.assetGrid.inDrawer {
    padding: 0px;
}

.assetGrid .assetItem {
    width: 10%;
    display: block;
    aspect-ratio: 1 / 1;
    float: left;
    position: relative;
    cursor: pointer;
    transition: background-color 250ms;
    overflow: hidden;
}

.assetGrid .editFolder {
    border: 1px solid #bbb;
    position: absolute;
    right: 8px;
    bottom: 8px;
}

.assetGrid .deleteFolder {
    border: 1px solid #bbb;
    position: absolute;
    right: 60px;
    bottom: 8px;
}

.assetGrid .fileDelete {
    border: 1px solid #bbb;
    position: absolute;
    right: 8px;
    bottom: 8px;
}

@media (max-width: 2000px) {
    .assetGrid .assetItem {
        width: 16.666%;
        aspect-ratio: 10 / 9;
    }
}

@media (max-width: 1500px) {
    .assetGrid .assetItem {
        width: 20%;
    }
}
@media (max-width: 1100px) {
    .assetGrid .assetItem {
        width: 25%;
    }
}

@media (max-width: 900px) {
    .assetGrid .assetItem {
        width: 33%;
    }
}

@media (max-width: 700px) {
    .assetGrid .assetItem {
        width: 50%;
    }
}

@media (max-width: 500px) {
    .assetGrid .assetItem {
        width: 100%;
    }
}

.assetGrid.inDrawer .assetItem {
    width: 50%;
}

.assetGrid .assetItem .backgroundImg {
    background-color: #fff;
    left: 4px;
    top: 4px;
    right: 4px;
    position: absolute;
    z-index: 1;
    aspect-ratio: 4 / 3;
    transition: opacity 250ms;
}

.assetGrid .assetItem:hover .backgroundImg {
    opacity: 0.8;
}

.assetGrid .assetItem .fileInfo {
    position: absolute;
    border-top: 1px solid #ddd;
    z-index: 2;
    left: 4px;
    bottom: 4px;
    right: 4px;
    background: #fff;
    padding: 8px;
    min-height: 45px;
}
